<template>
    <div @click="scrollToTop()" class="col-12 col-sm-12 col-md-6 px-3 mt-4 jackInTheBox">
        <router-link
            :to="rout"
            class="cabin d-block text-decoration-none"
        >
            <slot></slot>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "ButtonLink",
    props: {
        rout: {
            type: String,
            default: '/'
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    }
}
</script>

<style scoped>

.jackInTheBox {
    animation: jackInTheBox;
    animation-duration: 1s;
}

.cabin {
    width: auto;
    max-width: 445px;
    min-width: 150px;
    height: auto;
    min-height: 38px;
    border-radius: 10px;
    background-color: #80007F;
    color: #EAEAEA;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    padding-top: 9px;
    padding-left: 22.5px;
    margin: auto;
    cursor: pointer;
    transition: all 0.2s;
}

.cabin:hover {
    background-color: #990098;
}

</style>
