<template>
    <div>
        <Quote/>
        <div class="Group">
            <nav class="navbar navbar-expand-lg  navbar-dark">
                <div class="col-12 text-end">
                    <router-link class="addCourse pe-md-5 onlyBigSizeR" to="#"> Kabinet</router-link>
                </div>
            </nav>
        </div>
        <div v-if="getUserInfo.email.length > 0" class="col-12 col-sm-12 col-md-6 px-3">
            <div class="hello-user">
                Salom, {{ getUserInfo.givenName }} {{ getUserInfo.familyName }}!
                <router-link to="/edit-user">
                    <b-button style="border: 0; background-color: #FFFFFF">
                        <img
                            id="tooltip-target-1"
                            src="../../public/img/pencil.png"
                            alt=""
                            style="width: 25px; height: auto"
                            class="ms-2 pencil"
                        >
                    </b-button>
                    <b-tooltip target="tooltip-target-1" triggers="hover">
                        O'zgartirish
                    </b-tooltip>
                </router-link>
                <div>
                    ID raqamingiz: {{getUserInfo.id}}
                </div>
            </div>
        </div>

        <div class="row">
            <div v-if="isAdmin" class="row">
                <ButtonLink :rout="'/comments'">
                    Izohlar
                    <span v-if="newCommentsCount > 0" class="badge">{{ newCommentsCount }}</span>
                </ButtonLink>

                <ButtonLink :rout="'/users-list'">
                    Foydalanuvchilar ro'yxati
                </ButtonLink>

                <ButtonLink  :rout="'/teachers-list'">
                    Modul o'qituvchilari
                </ButtonLink>

                <ButtonLink :rout="'/request-for-call-list'">
                    Xabarlar
                    <span v-if="getAllNewRequestForCalls > 0" class="badge">{{ getAllNewRequestForCalls }}</span>
                </ButtonLink>

                <div class="hr mt-4 col-9 jackInTheBox">
                    <hr>
                </div>
            </div>


            <div v-if="isAdmin" class="row">
                <ButtonLink :rout="'/add-module'">
                    Kurs qo'shish
                </ButtonLink>

                <ButtonLink :rout="'/courses'">
                    Mavjud kurslar
                </ButtonLink>

                <ButtonLink :rout="'/course-type'">
                    Kurs turini qo'shish
                </ButtonLink>

                <ButtonLink :rout="'/exist-course-types-list'">
                    Mavjud kurs turlari
                </ButtonLink>

                <ButtonLink :rout="'/addCategory'">
                    Kategoriya qo'shish
                </ButtonLink>

                <ButtonLink :rout="'/exist-categories-list'">
                    Mavjud kategoriyalar
                </ButtonLink>

                <div class="hr mt-4 col-9 jackInTheBox">
                    <hr>
                </div>
            </div>


            <div v-if="isAdmin" class="row">
                <ButtonLink :rout="'/add-course'">
                    Modul qo'shish
                </ButtonLink>

                <ButtonLink :rout="'/course-archive'">
                    Mavjud modullar
                </ButtonLink>

                <ButtonLink :rout="'/add-student'">
                    Modulga o'quvchi qo'shish
                </ButtonLink>

                <ButtonLink :rout="'/course-students'">
                    Modul o'quvchilari
                </ButtonLink>

                <ButtonLink :rout="'/course-stop-admission'">
                    Modul qabulini to'xtatish
                </ButtonLink>

                <div class="hr mt-4 col-9 jackInTheBox">
                    <hr>
                </div>
            </div>

            <div v-if="isTeacher || isAdmin" class="row">
                <ButtonLink :rout="'/course-teacher'">
                    Mening o'quvchilarim
                </ButtonLink>

                <ButtonLink v-if="isAdmin" :rout="'/not-paid-students-courses'">
                    To'lov qilmagan o'quvchilar
                </ButtonLink>

                <ButtonLink v-if="isTeacher && !isAdmin" :rout="'/not-paid-teacher-students-courses'">
                    To'lov qilmagan o'quvchilar
                </ButtonLink>
                <div class="hr mt-4 col-9 jackInTheBox">
                    <hr>
                </div>
            </div>

            <div v-if="isAdmin" class="row">
                <ButtonLink :rout="'/promo-code'">
                    Promokod qo'shish
                </ButtonLink>

                <ButtonLink :rout="'/promo-codes'">
                Promokodlar ro'yxati
                </ButtonLink>

                <div class="hr mt-4 col-9 jackInTheBox">
                    <hr>
                </div>
            </div>
            <div class="col-12 col-sm-12 mt-4 col-md-6 px-3 jackInTheBox">
                <div class="cabin" @click="showModal()">
                    Kursga yozilish
                </div>
            </div>

            <b-modal ref="modal-select" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Ro'yxatdan o'tmoqchi bo'lgan kursingizni tanlang</h3>
                </div>

                <b-form-select
                    v-model="selected"
                    :options="options"
                    class="form-select form-control p-2 mt-5"
                    value-field="item"
                    text-field="name"
                ></b-form-select>

                <div class="d-block text-end mt-3">
                    <button
                        @click="send"
                        class="btn btn-success p-2 col-3"
                    >
                        Ok
                    </button>
                </div>
            </b-modal>

            <ButtonLink :rout="'/my-course'">
                Kurslarim
            </ButtonLink>

            <ButtonLink :rout="'/course-left-comment'">
                Izoh qoldirish
            </ButtonLink>

            <ButtonLink :rout="'/my-comments'">
                Izohlarim
            </ButtonLink>

        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";
import ButtonLink from "@/components/html/ButtonLink.vue";

export default {
    name: "CabinetPage",
    components: {ButtonLink, Quote},
    data() {
        return {
            show: true,
            selected: 'junior',
            options: [
                { item: 'junior', name: 'Web-full-stack-junior' },
                { item: 'middle', name: 'Web-full-stack-middle' },
            ]
        }
    },
    computed: {
        ...mapGetters([
            'isAdmin',
            'isTeacher',
            'getUser',
            'newCommentsCount',
            'getAccessToken',
            'getUserInfo',
            'getActiveCourses',
            'getAllNewRequestForCalls'
        ]),
        isAuthenticated() {
            return this.getAccessToken
        }
    },
    methods: {
        ...mapActions(['fetchUser', 'fetchAllNewComments', 'fetchActiveCourses', 'fetchAllNewRequestForCalls']),
        showModal() {
            this.$refs['modal-select'].show()
        },
        send() {
            switch (this.selected) {
                case 'junior':
                    this.$router.push('/course')
                    break
                default:
                    this.$router.push('/course-middle')
            }
        },
    },
    mounted() {
        this.fetchActiveCourses()
        this.show = true
        this.fetchUser()
        this.fetchAllNewRequestForCalls()
        this.show = false

        if (!this.isAuthenticated) {
            this.$router.push('/')
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    transition: all 0.7s;
}

*:hover {
    text-decoration: none;
}

.navbar {
    min-height: 87px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 24px;
    line-height: 27px;
    padding: 30px 15px 30px 15px;
}

.navbar:hover {
    background-color: #990098 !important;
}

.addCourse {
    color: #FFFFFF;
}

* {
    margin: 0;
    padding: 0;
}

.jackInTheBox {
    animation: jackInTheBox;
    animation-duration: 1s;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg") !important;
    background-repeat: no-repeat;
    background-size: 25px !important;
    border-color: #8B2BB9;
    border-radius: 10px;
}

.form-control:focus {
    box-shadow: 0 0 0 3px rgba(139, 43, 185, 0.37);
    border-color: #8B2BB9;
}

select::-ms-expand {
    display: none !important;
}

.cabin {
    width: auto;
    max-width: 445px;
    min-width: 150px;
    height: auto;
    min-height: 38px;
    border-radius: 10px;
    background-color: #80007F;
    color: #EAEAEA;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    padding-top: 9px;
    padding-left: 22.5px;
    margin: auto;
    cursor: pointer;
    transition: all 0.2s;
}

.cabin:hover {
    background-color: #990098;
}

.hello-user {
    width: auto;
    max-width: 445px;
    min-width: 150px;
    height: auto;
    min-height: 38px;
    border-radius: 10px;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    padding-top: 9px;
    padding-left: 22.5px;
    margin: auto;
}

#kabinet {
    margin: 111px 0;
}

.link {
    text-decoration: none;
    color: #FFFFFF;
}

.badge {
    color: red;
    position: relative;
    bottom: 10px;
    background-color: #FFFFFF;
    width: 20px;
    height: 15px;
    padding-top: 2px;
    padding-right: 2px;
}

.hr {
    margin: auto;
    background: #990098;
    height: 3px;
}

</style>
